import { MetricType } from '@wavingroup/aqora-v2-api/wavin/aqora/v2/metrics_pb';
import { TFunction } from 'i18next';

export type Unit =
  | 'mm'
  | '%'
  | '°C'
  | 'm/s'
  | 'dBm'
  | 'Open'
  | 'l'
  | 'l/s'
  | '°';

export const roundToNDecimals = (value: number, n: number) =>
  Math.round(value * 10 ** n) / 10 ** n;

export const roundToOneDecimal = (value: number) => roundToNDecimals(value, 1);

export function getUnit(metricType: MetricType): Unit | undefined {
  switch (metricType) {
    case MetricType.AIR_TEMPERATURE_CELSIUS:
    case MetricType.WATER_TEMPERATURE_CELSIUS:
      return '°C';
    case MetricType.WATER_HEIGHT_MM:
    case MetricType.WATER_HEIGHT_DIFFERENCE_MM:
    case MetricType.PRECIPITATION_MM:
    case MetricType.VALVE_HEIGHT_MM:
      return 'mm';
    case MetricType.BATTERY_PERCENTAGE:
    case MetricType.HUMIDITY_PERCENTAGE:
      return '%';
    case MetricType.WIND_SPEED_METER_PER_SECOND:
    case MetricType.WIND_GUST_SPEED_METER_PER_SECOND:
      return 'm/s';
    case MetricType.WIND_DIRECTION_DEGREES:
    case MetricType.WIND_GUST_DIRECTION_DEGREES:
      return '°';
    case MetricType.CELLULAR_RSSI_DBM:
      return 'dBm';
    case MetricType.FLOW_DIFFERENCE_L:
      return 'l';
    case MetricType.FLOW_RATE_L_SECOND:
      return 'l/s';
    case MetricType.TAP_ON:
    case MetricType.PUMP_STATE_ON:
    case MetricType.VALVE_STATE_ON:
      return undefined;
    default:
      throw new Error(`Unsupported metric type ${metricType}`);
  }
}

export function getSensorReadingLabel(t: TFunction, metricType: MetricType) {
  switch (metricType) {
    case MetricType.AIR_TEMPERATURE_CELSIUS:
      return t('system.sensorReading.labels.AIR_TEMPERATURE_CELSIUS');
    case MetricType.WATER_HEIGHT_MM:
      return t('system.sensorReading.labels.WATER_HEIGHT_MM');
    case MetricType.BATTERY_PERCENTAGE:
      return t('system.sensorReading.labels.BATTERY_PERCENTAGE');
    case MetricType.PRECIPITATION_MM:
      return t('system.sensorReading.labels.PRECIPITATION_MM');
    case MetricType.VALVE_HEIGHT_MM:
      return t('system.sensorReading.labels.VALVE_HEIGHT_MM');
    case MetricType.WATER_TEMPERATURE_CELSIUS:
      return t('system.sensorReading.labels.WATER_TEMPERATURE_CELSIUS');
    case MetricType.WIND_SPEED_METER_PER_SECOND:
      return t('system.sensorReading.labels.WIND_SPEED_METER_PER_SECOND');
    case MetricType.WIND_GUST_SPEED_METER_PER_SECOND:
      return t('system.sensorReading.labels.WIND_GUST_SPEED_METER_PER_SECOND');
    case MetricType.WIND_DIRECTION_DEGREES:
      return t('system.sensorReading.labels.WIND_DIRECTION_DEGREES');
    case MetricType.WIND_GUST_DIRECTION_DEGREES:
      return t('system.sensorReading.labels.WIND_GUST_DIRECTION_DEGREES');
    case MetricType.CELLULAR_RSSI_DBM:
      return t('system.sensorReading.labels.CELLULAR_RSSI_DBM');
    case MetricType.TAP_ON:
      return t('system.sensorReading.labels.TAP_ON');
    case MetricType.HUMIDITY_PERCENTAGE:
      return t('system.sensorReading.labels.HUMIDITY_PERCENTAGE');
    case MetricType.FLOW_DIFFERENCE_L:
      return t('system.sensorReading.labels.FLOW_DIFFERENCE_L');
    case MetricType.FLOW_RATE_L_SECOND:
      return t('system.sensorReading.labels.FLOW_RATE_L_SECOND');
    case MetricType.PUMP_STATE_ON:
      return t('system.sensorReading.labels.PUMP_STATE_ON');
    case MetricType.VALVE_STATE_ON:
      return t('system.sensorReading.labels.VALVE_STATE_ON');
    case MetricType.WATER_HEIGHT_DIFFERENCE_MM:
      return t('system.sensorReading.labels.WATER_HEIGHT_DIFFERENCE_MM');
    default:
      throw new Error(`Unsupported metric type ${metricType}`);
  }
}

function ensureNonNegativeValue(metricType: MetricType, value: number) {
  switch (metricType) {
    case MetricType.WATER_HEIGHT_MM:
    case MetricType.VALVE_HEIGHT_MM:
    case MetricType.BATTERY_PERCENTAGE:
    case MetricType.WIND_SPEED_METER_PER_SECOND:
    case MetricType.WIND_GUST_SPEED_METER_PER_SECOND:
    case MetricType.PRECIPITATION_MM:
    case MetricType.HUMIDITY_PERCENTAGE:
    case MetricType.FLOW_RATE_L_SECOND:
      return Math.max(value, 0);
    default:
      return value;
  }
}

export function roundNumericMetric(metricType: MetricType, value: number) {
  switch (metricType) {
    case MetricType.AIR_TEMPERATURE_CELSIUS:
    case MetricType.WATER_TEMPERATURE_CELSIUS:
    case MetricType.PCB_TEMPERATURE_CELSIUS:
    case MetricType.PRECIPITATION_MM:
    case MetricType.FLOW_DIFFERENCE_L:
    case MetricType.FLOW_RATE_L_SECOND:
      return roundToOneDecimal(value);
    case MetricType.WATER_HEIGHT_MM:
    case MetricType.VALVE_HEIGHT_MM:
    case MetricType.BATTERY_PERCENTAGE:
    case MetricType.HUMIDITY_PERCENTAGE:
    case MetricType.WATER_HEIGHT_DIFFERENCE_MM:
    case MetricType.WIND_SPEED_METER_PER_SECOND:
    case MetricType.WIND_GUST_SPEED_METER_PER_SECOND:
    case MetricType.WIND_DIRECTION_DEGREES:
    case MetricType.WIND_GUST_DIRECTION_DEGREES:
    case MetricType.CELLULAR_RSSI_DBM:
    case MetricType.TAP_ON:
    case MetricType.PUMP_STATE_ON:
    case MetricType.VALVE_STATE_ON:
      return Math.round(value);
    default:
      throw new Error(`Unsupported metric type ${metricType}`);
  }
}

type RoundedMetricProps = {
  metricType: MetricType;
  value: number | string | undefined;
};

export function getRoundedMetricValue({
  metricType,
  value,
}: RoundedMetricProps): number | string | undefined {
  if (value === undefined) {
    return undefined;
  }

  if (typeof value === 'string') {
    return value;
  }

  return roundNumericMetric(
    metricType,
    ensureNonNegativeValue(metricType, value),
  );
}
