import { QueryClient } from '@tanstack/react-query';
import {
  ListMetricsRequest_Target,
  ListSystemsResponse,
} from '@wavingroup/aqora-v2-api/wavin/aqora/v2/aqora_service_pb';
import { LoaderFunctionArgs } from 'react-router';
import { MetricType } from '@wavingroup/aqora-v2-api/wavin/aqora/v2/metrics_pb';
import { chartMetricQuery } from '~/shared/api/metrics.queries';
import { listSystemsQuery } from '~/shared/api/system.queries';
import { MetricDataSet } from './types';
import { METRICS_DATA_PARAM_NAME } from '~/shared/models/create-routes';
import { assertIsDefined } from '~/types/assert-type';
import { checkAuthorization } from '~/shared/auth/auth-utils';
import { ChartMetricModel } from '~/shared/models/metrics/ChartMetricModel';

export type MetricsSystemLoaderResult = {
  systems: ListSystemsResponse;
};

export function metricsSystemsLoader(queryClient: QueryClient) {
  return async ({
    request,
  }: LoaderFunctionArgs): Promise<MetricsSystemLoaderResult> => {
    await checkAuthorization(queryClient, request);

    const listSystemsData = await queryClient.ensureQueryData(listSystemsQuery);

    return { systems: listSystemsData };
  };
}

export type MetricsLoaderResult = { metrics: ChartMetricModel[] };

export function metricsLoader(queryClient: QueryClient) {
  return async ({
    request,
    params,
  }: LoaderFunctionArgs): Promise<MetricsLoaderResult> => {
    await checkAuthorization(queryClient, request);

    const { systemId } = params;

    assertIsDefined(systemId);

    const { searchParams } = new URL(request.url);

    const data = JSON.parse(
      decodeURIComponent(searchParams.get(METRICS_DATA_PARAM_NAME) || ''),
    ) as MetricDataSet;

    const promises = data
      .filter(({ metricType }) => !!metricType)
      .map(({ resourceName, metricType }) => {
        assertIsDefined(metricType);
        return queryClient.ensureQueryData(
          chartMetricQuery({
            target: new ListMetricsRequest_Target({
              resourceName,
              types: [MetricType[metricType]],
            }),
          }),
        );
      });

    return { metrics: await Promise.all(promises) };
  };
}
