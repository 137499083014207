import {
  Metric,
  MetricType,
} from '@wavingroup/aqora-v2-api/wavin/aqora/v2/metrics_pb';
import { TFunction } from 'i18next';
import {
  DataPoint,
  getOutdatedReadingLabel,
  isMetricReadingOutdated,
  mapDataPoint,
} from '~/shared/models/metrics/metrics-utils';
import {
  getSensorReadingLabel,
  getUnit,
  Unit,
} from '~/shared/models/metrics/metric-conversions';

function getTapStatus(
  t: TFunction,
  value: string | number | undefined,
): string {
  switch (String(value)) {
    case '0':
      return t('system.sensorReading.tapStatus.TAP_CLOSED');
    case '1':
      return t('system.sensorReading.tapStatus.TAP_OPENED');
    default:
      return '';
  }
}

function getValveStatus(
  t: TFunction,
  value: string | number | undefined,
): string {
  switch (String(value)) {
    case '0':
      return t('system.sensorReading.valveStatus.VALVE_CLOSED');
    case '1':
      return t('system.sensorReading.valveStatus.VALVE_OPENED');
    default:
      return '';
  }
}

function getPumpStatus(
  t: TFunction,
  value: string | number | undefined,
): string {
  switch (String(value)) {
    case '0':
      return t('system.sensorReading.pumpStatus.DISABLED');
    case '1':
      return t('system.sensorReading.pumpStatus.ENABLED');
    default:
      return '';
  }
}

export class MetricModel {
  readonly resourceName: string;

  readonly type: MetricType;

  readonly lastDataPoint: DataPoint | undefined = undefined;

  readonly unit: Unit | undefined;

  constructor(metric: Metric) {
    this.resourceName = metric.resourceName;
    this.type = metric.type;

    const lastMetricDataPoint = metric.dataPoints.at(-1);

    if (lastMetricDataPoint) {
      this.lastDataPoint = mapDataPoint(lastMetricDataPoint);
    }

    this.unit = getUnit(this.type);
  }

  getLastValue(t: TFunction): string | number | undefined {
    const lastValue = this.getLastNumberValue();
    if (lastValue === undefined) {
      return undefined;
    }

    return this.metricValueMapper(t, lastValue);
  }

  getLastNumberValue(): number | undefined {
    return this.lastDataPoint?.[1] ?? undefined;
  }

  getLatestTimestampLabel(t: TFunction) {
    const { lastDataPoint } = this;

    if (!lastDataPoint) {
      return null;
    }

    if (!isMetricReadingOutdated(lastDataPoint[0])) {
      return null;
    }

    return getOutdatedReadingLabel(t, lastDataPoint[0]);
  }

  getLabel = (t: TFunction) => getSensorReadingLabel(t, this.type);

  protected metricValueMapper(t: TFunction, value: number) {
    switch (this.type) {
      case MetricType.TAP_ON:
        return getTapStatus(t, value);
      case MetricType.PUMP_STATE_ON:
        return getPumpStatus(t, value);
      case MetricType.VALVE_STATE_ON:
        return getValveStatus(t, value);
      default:
        return value;
    }
  }
}
